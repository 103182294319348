import React from 'react';
import { Tag, TagLabel, TagCloseButton, Flex } from '@chakra-ui/react';
import { useCurrentRefinements } from 'react-instantsearch-hooks-web';
import { useNavigate, useLocation } from 'react-router-dom';

const CustomCurrentRefinements = () => {
  const { items, refine } = useCurrentRefinements(); // Get current refinements and refine method
  const navigate = useNavigate();
  const location = useLocation();

  const handleRemove = (refinementItem, refinement) => {
    const queryParams = new URLSearchParams(location.search);

    if (refinementItem.attribute === 'taxonomies.price') {
      // Identify minPrice and maxPrice based on Algolia's labels
      let minPrice = queryParams.get('minPrice') ? Number(queryParams.get('minPrice')) : -Infinity;
      let maxPrice = queryParams.get('maxPrice') ? Number(queryParams.get('maxPrice')) : Infinity;

      // If removing minPrice
      if (refinement.label.startsWith('≥')) {
        queryParams.delete('minPrice');
        minPrice = -Infinity; // Reset minPrice
      }

      // If removing maxPrice
      if (refinement.label.startsWith('≤')) {
        queryParams.delete('maxPrice');
        maxPrice = Infinity; // Reset maxPrice
      }

      // Refine the price range with updated minPrice and maxPrice
      refine([minPrice, maxPrice]);

      // Update the URL to reflect the changes
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    } else {
      // Handle other types of refinements
      refine({
        attribute: refinementItem.attribute,
        value: refinement.value,
        type: refinement.type,
      });

      // Update the URL by removing the corresponding query parameter
      const existingValues = queryParams.getAll(refinementItem.attribute);
      const updatedValues = existingValues.filter(
        (value) => decodeURIComponent(value) !== refinement.label
      );

      queryParams.delete(refinementItem.attribute); // Remove the full parameter

      // Re-add the remaining values, if any, for that attribute
      updatedValues.forEach((value) => {
        queryParams.append(refinementItem.attribute, value);
      });

      // Update the URL without the removed refinement
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }
  };

  return (
    <Flex wrap="wrap" gap={2}>
      {items.map((refinementItem) =>
        refinementItem.refinements.map((refinement) => {
          // Determine if this is a minPrice or maxPrice tag based on the Algolia label
          const isMinPrice = refinement.label.startsWith('≥');
          const isMaxPrice = refinement.label.startsWith('≤');

          // Adjust the key for price refinement based on whether it's min or max
          const key = isMinPrice
            ? 'minPrice'
            : isMaxPrice
            ? 'maxPrice'
            : `${refinementItem.attribute}-${refinement.value}`;

          return (
            <Tag size="md" key={key} variant="solid" colorScheme="pink" mb="2">
              <TagLabel>{`${refinement.label}`}</TagLabel>
              <TagCloseButton
                onClick={() => handleRemove(refinementItem, refinement)} // Remove only the specific refinement
              />
            </Tag>
          );
        })
      )}
    </Flex>
  );
};

export default CustomCurrentRefinements;
