import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Button,
  Link as ChakraLink,
  Accordion,
  ButtonGroup,
  Skeleton,
  SkeletonText
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useSearchParams, useLocation } from 'react-router-dom';
import { useHits, usePagination } from 'react-instantsearch-hooks-web';
import CustomRefinementList from '../components/CustomRefinementList';
import CustomPagination from '../components/CustomPagination';
import CustomPriceRangeInput from '../components/CustomPriceRangeInput';
import CustomCurrentRefinements from '../components/CustomCurrentRefinements';


// Custom Hits Component
const CustomHits = () => {
  const { hits, results } = useHits();
  const isLoading = !results || results.nbHits === 0; // Determine loading state
  
  const generateProductListJsonLd = () => {
    const itemListElement = hits.map((hit, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `${window.location.origin}/product/${hit.slug}`
    }));

    return (
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": itemListElement
        })}
      </script>
    );
  };

  return (
    <SimpleGrid columns={[1, 2, 3]} spacing="6">
      {generateProductListJsonLd()}
      {isLoading ? (
        // Display skeletons if loading
        [...Array(6)].map((_, i) => (
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" key={i}>
            <Skeleton height="200px" />
            <Box p="6">
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </Box>
          </Box>
        ))
      ) : (
        // Display products if loaded
        hits.map((hit) => (
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" key={hit.objectID}>
            <ChakraLink as={RouterLink} to={`/product/${hit.slug}`} flex="1">
              <Image
                src={hit.images.thumbnail.url}
                alt={hit.post_title}
                width="100%"
                height="auto"
                objectFit="cover"
                loading="lazy"
                _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }}
              />
            </ChakraLink>
            <Box p="6">
              <Stack spacing="3">
                <Text fontSize="sm" textTransform="uppercase">
                  {hit.taxonomies.product_cat}
                </Text>
                <Text fontWeight="bold" fontSize="xl">{hit.post_title}</Text>
                <Text fontSize="xl">{hit.price} €</Text>
                <Button as={RouterLink} to={`/product/${hit.slug}`} colorScheme="pink">
                  Περισσότερα
                </Button>
              </Stack>
            </Box>
          </Box>
        ))
      )}
    </SimpleGrid>
  );
};

const SearchResults = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query') || '';
  const [isLoading, setIsLoading] = useState(true); // Simulate loading state for filters and other elements

  const location = useLocation(); // Get the current URL location
  const [CategoryParams, setCategoryParams] = useState([]); // State to store the 'taxonomies' parameters
  const [BrandParams, setBrandParams] = useState([]); // State to store the 'taxonomies' parameters
  const [ColorParams, setColorParams] = useState([]); // State to store the 'taxonomies' parameters
  const [SizeParams, setSizeParams] = useState([]); // State to store the 'taxonomies' parameters

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Get all query parameters
    const categoriesArray = [];
    const brandArray = [];
    const colorArray = [];
    const sizeArray = [];
    
    // Iterate through all query parameters
    queryParams.forEach((value, key) => {
      // Check if the parameter key starts with 'taxonomies'
      if (key === 'taxonomies.product_cat') {
        categoriesArray.push(decodeURIComponent(value)); // Push the value into the array
      }
      if (key === 'taxonomies.pa_brand') {
        brandArray.push(decodeURIComponent(value)); // Push the value into the array
      }
      if (key === 'taxonomies.pa_color') {
        colorArray.push(decodeURIComponent(value)); // Push the value into the array
      }
      if (key === 'taxonomies.pa_size') {
        sizeArray.push(decodeURIComponent(value)); // Push the value into the array
      }
    });

    // Update the state with the 'taxonomies' parameters
    setCategoryParams(categoriesArray);
    setBrandParams(brandArray);
    setColorParams(colorArray);
    setSizeParams(sizeArray);
  }, [location.search]);


  // Simulate loading state for filters and other elements (you can replace this with actual loading logic)
  setTimeout(() => setIsLoading(false), 100);
  return (
    <Box p="6" maxW="1320px" mx="auto">
      {/* Breadcrumb Navigation */}
      <Box overflowX="auto" whiteSpace="nowrap" mb="6">
        <Breadcrumb display="inline-block">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>Αποτελέσματα αναζήτησης για "{searchQuery || ''}"</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      {/* Heading */}
      <Heading as="h1" size="xl" mb="5">
        {isLoading ? <Skeleton height="40px" width="300px" /> : `Αποτελέσματα αναζήτησης για "${searchQuery}"`}
      </Heading>

      {/* Mobile Filters Button */}
      <ButtonGroup size='sm' isAttached variant='outline' display={{ base: 'block', md: 'none' }}>
        <Button onClick={onOpen}>Φίλτρα</Button>
        <IconButton
          aria-label="Filter"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          mb="4"
          mt="4"
        />
      </ButtonGroup>

      {/* Filters and Search Results */}
      <Flex direction={['column', 'row']} gap={6}>
        {/* Sidebar Filters */}
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Box p="6" borderWidth="1px" borderRadius="lg">
            <Heading mb={4} size='lg' fontSize='24px'>
              {isLoading ? <Skeleton height="24px" width="100px" /> : 'Φίλτρα'}
            </Heading>
            {isLoading ? (
              // Display skeletons for filters if loading
              <>
                <Skeleton height="50px" mb="4" />
                <Skeleton height="50px" mb="4" />
                <Skeleton height="50px" mb="4" />
              </>
            ) : (
              <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                <CustomCurrentRefinements />
                <CustomRefinementList attribute="taxonomies.product_cat" title="Κατηγορίες" facetFilter={CategoryParams}/>
                <CustomRefinementList attribute="taxonomies.pa_brand" title="Μάρκα" facetFilter={BrandParams} />
                <CustomRefinementList attribute="taxonomies.pa_color" title="Χρώμα" facetFilter={ColorParams} />
                <CustomRefinementList attribute="taxonomies.pa_size" title="Μέγεθος" facetFilter={SizeParams} />
                <CustomPriceRangeInput attribute="taxonomies.price" />
              </Accordion>
            )}
          </Box>
        </Box>

        {/* Search Results */}
        <Box flex="3">
          <CustomHits />
          {isLoading ? (
            <Skeleton height="30px" width="200px" mt="4" />
          ) : (
            <CustomPagination />
          )}
        </Box>
      </Flex>

      {/* Drawer for mobile filters */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{isLoading ? <Skeleton height="30px" width="100px" /> : 'Φίλτρα'}</DrawerHeader>
          <DrawerBody>
            {isLoading ? (
              // Display skeletons for mobile filters if loading
              <>
                <Skeleton height="50px" mb="4" />
                <Skeleton height="50px" mb="4" />
                <Skeleton height="50px" mb="4" />
              </>
            ) : (
              <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                <CustomCurrentRefinements />
                <CustomRefinementList attribute="taxonomies.product_cat" title="Κατηγορίες" facetFilter={CategoryParams}/>
                <CustomRefinementList attribute="taxonomies.pa_brand" title="Μάρκα" facetFilter={BrandParams} />
                <CustomRefinementList attribute="taxonomies.pa_color" title="Χρώμα" facetFilter={ColorParams} />
                <CustomRefinementList attribute="taxonomies.pa_size" title="Μέγεθος" facetFilter={SizeParams} />
                <CustomPriceRangeInput attribute="taxonomies.price" />
              </Accordion>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Αποτελέσματα
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default SearchResults;
