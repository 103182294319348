import React, { useState, useEffect, useRef } from 'react';
import { Box, Input, Button, Flex, Text, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { useRange } from 'react-instantsearch-hooks-web';

const CustomPriceRangeInput = (props) => {
  const { start, range, canRefine, refine } = useRange(props);
  const { min, max } = range;

  // State for min and max prices
  const [minValue, setMinValue] = useState(start[0] === -Infinity ? min : start[0]);
  const [maxValue, setMaxValue] = useState(start[1] === Infinity ? max : start[1]);

  // Synchronize input values with Algolia's initial `start` values
  useEffect(() => {
    setMinValue(start[0] === -Infinity ? min : start[0]);
    setMaxValue(start[1] === Infinity ? max : start[1]);
  }, [start, min, max]);

  // Handle min price input change
  const handleMinChange = (e) => setMinValue(e.target.value);

  // Handle max price input change
  const handleMaxChange = (e) => setMaxValue(e.target.value);

  // Apply the price range filter and update the URL
  const handleApplyFilter = () => {
    const refinedMin = Math.max(min, Number(minValue) || min);
    const refinedMax = Math.min(max, Number(maxValue) || max);

    // Apply the price range filter
    refine([refinedMin, refinedMax]);

  };

  // Reset the filter and remove it from the URL
  const handleResetFilter = () => {
    setMinValue(min);
    setMaxValue(max);
    refine([min, max]);
  };

  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          Τιμή
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel p="1">
        <Box p="2">
          <Flex gap="1" alignItems="center">
            <Input
              placeholder={`Min ${min}€`} // Set the placeholder to the min price
              value={minValue}
              onChange={handleMinChange}
              size="sm"
              type="number"
            />
            <Text>-</Text>
            <Input
              placeholder={`Max ${max}€`} // Set the placeholder to the max price
              value={maxValue}
              onChange={handleMaxChange}
              size="sm"
              type="number"
            />
          </Flex>

          <Flex justifyContent="space-between" mt="4">
            <Button size="sm" onClick={handleResetFilter}>
              Reset
            </Button>
            <Button size="sm" colorScheme="pink" onClick={handleApplyFilter}>
              Apply
            </Button>
          </Flex>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CustomPriceRangeInput;
