import React, { useState, useRef } from 'react';
import {
  Box,
  Image,
  HStack,
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useDisclosure,
  List,
  ListItem,
  Portal
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { HamburgerIcon, SearchIcon, CloseIcon } from '@chakra-ui/icons';
import HeaderNavigation from './HeaderNavigation';
import logo from '../assets/fe-logo.png';
import api from '../woocommerce'; // Replace with your API instance for suggestions
import CustomSearchBox from '../components/CustomSearchBox';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Control the drawer state
  const [isSearchOpen, setIsSearchOpen] = useState(false); // Control the search input visibility
  const [searchQuery, setSearchQuery] = useState(''); // State for search input value
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions
  const [showSuggestions, setShowSuggestions] = useState(false); // Control suggestions dropdown visibility
  const navigate = useNavigate(); // Navigation hook for redirecting to search results
  
  const abortControllerRef = useRef(null); // Reference to store the AbortController

  // Fetch search suggestions when the user types
  const fetchSearchSuggestions = async (query) => {
    if (query.length < 2) {
      setSuggestions([]); // Clear suggestions if the query is too short
      return;
    }

    // Cancel any ongoing request before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Create a new controller for the current request
    abortControllerRef.current = new AbortController();
    
    try {
      const response = await api.get('/products', {
        params: { search: query, per_page: 5 },
        signal: abortControllerRef.current.signal // Pass the abort signal
      });
      setSuggestions(response.data);
      setShowSuggestions(true);
    } catch (error) {
      
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    if (value.length >= 3) {
      fetchSearchSuggestions(value); // Fetch suggestions when input has 3 or more characters
    } else {
      setSuggestions([]); // Clear suggestions if input has fewer than 3 characters
      setShowSuggestions(false);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    
    // Cancel any ongoing fetch suggestions request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    if (searchQuery.trim() !== '') {
      navigate(`/search?query=${searchQuery}`);
      setSearchQuery(''); // Clear the input after search
      setIsSearchOpen(false); // Close the search input after search
      setShowSuggestions(false); // Hide suggestions after search
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.name); // Fill the input with the selected suggestion
    setShowSuggestions(false); // Hide the suggestions dropdown
    navigate(`/search?query=${suggestion.name}`); // Redirect to search results
  };

  const clearSearch = () => {
    setSearchQuery(''); // Clear the input
    setShowSuggestions(false); // Hide suggestions
  };

  // Toggle the mobile search input
  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <Box bg="pink.500" boxShadow="md">
      {/* Desktop Layout */}
      <HStack spacing="24px" alignItems="center" display={{ base: 'none', md: 'flex' }}>
        
        {/* Logo Section */}
        <Box bg="white" padding="4" w="210px" h="80px">
          <Flex alignItems="center" justifyContent="center" h="100%">
            <RouterLink to="/">
              <Image
                src={logo}  // Replace with your logo path
                alt="Fashion-editor.gr"
                height="50px"  // Adjust the height as needed
              />
            </RouterLink>
          </Flex>
        </Box>

        {/* Navigation and Search Section */}
        <Box bg="pink.500" padding="2" color="white" w="100%" h="80px">
          <Flex alignItems="center" justifyContent="space-between" h="100%">
            
            {/* Navigation */}
            <HeaderNavigation />

            {/* Algolia SearchBox */}
            <CustomSearchBox />

          </Flex>
        </Box>
      </HStack>

      {/* Mobile Layout */}
      <Flex 
        alignItems="center" 
        justifyContent="space-between" 
        padding="2" 
        display={{ base: 'flex', md: 'none' }} 
        bg="pink.500"
        color="white"
      >
        {/* Hamburger Menu Icon */}
        <IconButton
          aria-label="Menu"
          icon={<HamburgerIcon />}
          variant="outline"
          color="white"
          border="none"
          onClick={onOpen}
        />

        {/* Logo in the Center */}
        <RouterLink to="/">
          <Image
            src={logo}  // Replace with your logo path
            alt="Fashion-editor.gr"
            height="40px"  // Adjust the height as needed
          />
        </RouterLink>

        {/* Search Icon */}
        {isSearchOpen ? (
          <IconButton
            aria-label="Close Search"
            icon={<CloseIcon />}  
            variant="outline"
            color="white"
            border="none"
            onClick={toggleSearch}  
          />
        ) : (
          <IconButton
            aria-label="Search"
            icon={<SearchIcon />}  
            variant="outline"
            color="white"
            border="none"
            onClick={toggleSearch} 
          />
        )}
        
      </Flex>

      {/* Search Input (Visible when toggled) */}
      {isSearchOpen && (
        <Box bg="pink.500" padding="2" display={{ base: 'block', md: 'none' }}>
          {/* Algolia SearchBox */}
          <CustomSearchBox />
        </Box>
      )}

      {/* Mobile Menu Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody bg="pink.700" padding="4" color="white">
            <HeaderNavigation isMobile={true} onClose={onClose} /> {/* Pass onClose prop */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
