import React, { useEffect, useState } from 'react';
import { HStack, Box, Button, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { usePagination } from 'react-instantsearch-hooks-web';
import { useNavigate, useLocation } from 'react-router-dom';

const CustomPagination = () => {
  const { currentRefinement, nbPages, refine } = usePagination();
  const [currentPage, setCurrentPage] = useState(currentRefinement + 1); // Start from page 1 (1-based UI)
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    // Adjust current page to sync with Algolia's 0-based pagination
    setCurrentPage(currentRefinement + 1);
     // Iterate through all query parameters
    let queryParams = new URLSearchParams(location.search); // Get all query parameters
    queryParams.forEach((value, key) => {
      // Check if the parameter key is 'page'
      if (key === 'page') {
        refine(value-1);
      }
    });
  }, [currentRefinement]);

 

  // Function to update the URL with the current page number
  const updateUrlWithPage = (page) => {
    const queryParams = new URLSearchParams(location.search);

    // Remove the previous page number from the URL
    queryParams.delete('page');

    // Add the current page to the URL, but only if it's not the first page
    if (page > 1) {
      queryParams.append('page', page);
    }

    // Update the URL
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  const handlePageChange = (page) => {
    refine(page - 1); // Adjust by -1 for 0-based indexing in Algolia
    setCurrentPage(page);
    updateUrlWithPage(page); // Update the URL with the new page number
  };

  const generatePages = (currentPage, totalPages) => {
    const delta = 2; // Range of visible page numbers
    const range = [];

    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift('...');
    }
    if (currentPage + delta < totalPages - 1) {
      range.push('...');
    }

    range.unshift(1); // Always show page 1
    if (totalPages > 1) {
      range.push(totalPages); // Always show the last page
    }

    return range;
  };

  const pages = generatePages(currentPage, nbPages);

  // Hide pagination if there is only 1 page
  if (nbPages <= 1) {
    return null;
  }

  return (
    <HStack spacing="4" justify="center" mt="8">
      <IconButton
        icon={<ChevronLeftIcon />}
        isDisabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)} // Adjust for 1-based UI
        aria-label="Previous page"
      />
      {pages.map((page, index) =>
        page === '...' ? (
          <Box key={index} px="3" py="2">...</Box>
        ) : (
          <Button
            key={index}
            onClick={() => handlePageChange(page)} // Adjust for 1-based UI
            isActive={currentPage === page}
          >
            {page}
          </Button>
        )
      )}
      <IconButton
        icon={<ChevronRightIcon />}
        isDisabled={currentPage === nbPages}
        onClick={() => handlePageChange(currentPage + 1)} // Adjust for 1-based UI
        aria-label="Next page"
      />
    </HStack>
  );
};

export default CustomPagination;
