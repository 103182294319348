import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Checkbox,
  Stack,
  Input,
} from '@chakra-ui/react';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { useNavigate, useLocation } from 'react-router-dom';

// Custom Refinement List Component
const CustomRefinementList = ({ attribute, title, facetFilter }) => {
  const { items, refine, canRefine } = useRefinementList({ attribute });
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();  // To manipulate the URL
  const location = useLocation();  // To get the current location
  const hasRunOnce = useRef(false); // Track whether the function has run

  // Effect to set the current refinement based on facetFilter (only runs once)
  useEffect(() => {
    if (!hasRunOnce.current && facetFilter) {
      if (Array.isArray(facetFilter)) {
        facetFilter.forEach((value) => refine(value)); // Apply each value as a refinement
      } else {
        refine(facetFilter); // Apply the single value as a refinement
      }
      hasRunOnce.current = true; // Mark that the function has run
    }
  }, [facetFilter, refine]);  // Ensure this effect runs once based on facetFilter

  // Filter items based on search value
  const filteredItems = searchValue
    ? items.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
    : items;

  // Function to update the URL with all selected filters, preserving other attributes
  const updateUrlWithFilters = (newItems, value) => {
    const queryParams = new URLSearchParams(location.search); // Get existing params

    // Remove the current attribute's previous filters
    queryParams.delete(attribute);
    // Remove the previous page number from the URL
    queryParams.delete('page');

    // Add the current attribute's selected filters to the URL
    newItems
      .filter((item) => item.value === value && !item.isRefined || item.value !== value && item.isRefined) // Only include selected items
      .forEach((item) => {
        const encodedValue = encodeURIComponent(item.label); // Encode the label
        queryParams.append(attribute, encodedValue); // Add it to the URL as `attribute=value`
      });

    // Push the updated URL preserving other refinements
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(), // Include the updated query string
    });
  };

  // Handle the refinement toggle and update the URL
  const handleRefine = (value) => {
    refine(value);  // Refine the selected value
    // Update the URL with all the selected refinements for this attribute
    updateUrlWithFilters(items, value);
 
  };

  // Render nothing if there are no items to refine
  if (!canRefine) {
    return null;
  }

  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {items.length > 5 && (
          <Input
            placeholder="Αναζήτηση..."
            mb={4}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        )}
        <Stack maxHeight="150px" overflowY="auto">
          {filteredItems.map((item) => (
            <Checkbox
              key={item.label}
              isChecked={item.isRefined} // Use item.isRefined directly
              onChange={() => handleRefine(item.value)} // Trigger refinement and URL update
            >
              <Text>
                {item.label} <Text as="span" color="gray.500">({item.count})</Text>
              </Text>
            </Checkbox>
          ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CustomRefinementList;
