import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  IconButton,
  List,
  ListItem,
  Portal
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { useSearchBox, useHits } from 'react-instantsearch-hooks-web';
import { useNavigate } from 'react-router-dom';

const CustomSearchBox = () => {
  const { query, refine } = useSearchBox();
  const { hits } = useHits();
  const [searchQuery, setSearchQuery] = useState(query);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Update suggestions based on hits from Algolia
  useEffect(() => {
    if (hits.length > 0 && searchQuery) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [hits, searchQuery]);

  // Handle input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    if(value !== ''){
      setSearchQuery(value);
      refine(value);
    }
  };

  // Handle form submit
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if(searchQuery !== ''){
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
      setShowSuggestions(false); // Hide suggestions on search submit
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    const suggestionName = suggestion.name || suggestion.post_title; // Use name or post_title
    if (suggestionName) {
      setSearchQuery(suggestionName);
      navigate(`/product/${encodeURIComponent(suggestion.slug)}`);
      setShowSuggestions(false);
    }
  };

  // Handle mouse leave on suggestion box
  const handleMouseLeave = () => {
    setShowSuggestions(false);
  };

  // Clear search input without triggering Algolia refinement
  const clearSearch = () => {
    setSearchQuery(''); // Only clear the input field
    setShowSuggestions(false); // Hide suggestions
  };

  return (
    <form id="product-search-form" role="search" onSubmit={handleSearchSubmit} style={{ position: 'relative' }}>
      <InputGroup>
        {searchQuery ? (
          <InputLeftElement>
            <IconButton
              aria-label="Clear search"
              icon={<CloseIcon />}
              onClick={clearSearch} // Clear search input
              variant="ghost"
              color="gray.500"
            />
          </InputLeftElement>
          ) : ('')}
        <Input
          placeholder="Search..."
          bg="white"
          width="100%"
          color="black"
          _placeholder={{ color: 'gray.500' }}
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={() => setShowSuggestions(true)} // Show suggestions on input focus
        />
        <InputRightElement>
          <IconButton
            aria-label="Search"
            icon={<SearchIcon />}
            type="submit"
            variant="ghost"
            color="gray.500"
          />
        </InputRightElement>
      </InputGroup>

      {/* Autosuggest Dropdown */}
      {showSuggestions && (
        <Portal>
          <Box
            bg="pink.500"
            color="white"
            mt="2"
            borderRadius="md"
            boxShadow="lg"
            position="absolute"
            top={{ base: '100px', md: '55px' }} // Position it directly below the input
            right={{ base: '0px', md: '8px' }}
            width={{ base: '100%', md: '400px' }}
            zIndex="10"
            onMouseLeave={handleMouseLeave} // Hide suggestions on mouse leave
          >
            <List spacing={2}>
              {hits.slice(0, 5).map((hit) => ( // Limit suggestions to 5
                <ListItem
                  key={hit.objectID}
                  p="2"
                  _hover={{ backgroundColor: 'pink.600' }}
                  onMouseDown={() => handleSuggestionClick(hit)} // Handle click on suggestion
                >
                  {hit.name || hit.post_title}
                </ListItem>
              ))}
            </List>
          </Box>
        </Portal>
      )}
    </form>
  );
};

export default CustomSearchBox;
